import React, { useState } from "react";
import classnames from "classnames";
import StarRatings from "react-star-ratings";
import { HTMLContent } from "../Content";

import styles from "./styles.module.less";

const TestimonialsCarousel = ({
    title = "WHAT PEOPLE ARE SAYING",
    items = [],
    useHtml = false,
}) => {
    const [active, setActive] = useState(0);
    const [direction, setDirection] = useState("");

    const renderSlide = (item, index) => {
        let position;

        if (active === index) {
            position = `${styles.active}`;
        } else if (active === index + 1) {
            position = `${styles.prev}`;
        } else if (active === index - 1) {
            position = `${styles.next}`;
        } else if (active > index) {
            position = `${styles.left}`;
        } else if (active < index) {
            position = `${styles.right}`;
        }

        return (
            <div
                key={index}
                className={
                    position
                        ? `${styles.Testimonial} ${position}`
                        : styles.Testimonial
                }>
                <div className={styles.Testimonial__review}>
                    {!!item.rating && (
                        <StarRatings
                            rating={parseFloat(item.rating)}
                            starDimension="22px"
                            starSpacing="2px"
                            starRatedColor="rgba(255,138,0,1)"
                        />
                    )}
                </div>
                <div className={styles.Testimonial__text}>
                    {useHtml ? (
                        <HTMLContent
                            content={item.text}
                            className={styles.Testimonial__html}></HTMLContent>
                    ) : (
                        <>“{item.text}”</>
                    )}
                    <div className={styles.Testimonial__name}>
                        {(item.name ? "- " + item.name : "") +
                            (item.title ? ", " + item.title : "") +
                            (item.city ? ", " + item.city : "") +
                            (item.state ? " " + item.state : "")}
                    </div>
                </div>
                {item.reply && (
                    <div
                        className={classnames(
                            styles.Testimonial__text,
                            styles.Testimonial__reply,
                        )}>
                        <div>“{item.reply.reply.trim()}”</div>
                        <div className={styles.Testimonial__name}>
                            - Reply from {item.reply.name}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderSliderNav = () => {
        return items.map((item, index) => (
            <button
                onClick={() => sliderNavClick(index)}
                key={index}
                className={classnames({ [styles.active]: active === index })}>
                <li
                    className={classnames(styles.LessonSlide__navDot, {
                        [styles.active]: active === index,
                    })}
                />
            </button>
        ));
    };

    const sliderNavClick = selected => {
        const direction = selected > active ? "right" : "left";

        setActive(selected);
        setDirection(direction);
    };

    const moveLeft = () => {
        const newActive = active - 1;

        if (active + 1 === 1) {
            return;
        }

        setActive(newActive < 0 ? items.length - 1 : newActive);
        setDirection("left");
    };

    const moveRight = () => {
        const newActive = active;

        if (active + 1 === items.length) {
            return;
        }

        setActive((newActive + 1) % items.length);
        setDirection("right");
    };

    if (!items || !items.length) {
        return null;
    }

    return (
        <div className={styles.Testimonial__wrapper}>
            <div className={styles.Testimonial__container}>
                <h3 id="testimonials">{title.toUpperCase()}</h3>
                <div
                    className={`${styles.TestimonialSlider__container} ${styles.noselect}`}>
                    {active + 1 !== 1 && (
                        <button
                            className={`${styles.TestimonialSlider__button} ${styles.TestimonialSlider__button__prev}`}
                            onClick={moveLeft}
                        />
                    )}
                    {items &&
                        items.map((item, index) => renderSlide(item, index))}
                    {active + 1 !== items.length && (
                        <div
                            className={`${styles.TestimonialSlider__button} ${styles.TestimonialSlider__button__next}`}
                            onClick={moveRight}
                        />
                    )}
                </div>
                <div>
                    <ul
                        className={`${styles.TestimonialSlide__nav} ${styles.noselect}`}>
                        {renderSliderNav()}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TestimonialsCarousel;
